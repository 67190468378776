import { withAuth0 } from '@auth0/auth0-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from './AppNavbar';

const MSEndpoint = require("./config").MSEndpoint

class OrgList extends Component {
    constructor(props) {
        super(props);
        this.state = {orgs: []};
        this.config = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }
        
    }
    
    componentDidMount() {
        fetch(MSEndpoint+'/org', this.config)
            .then(response => {
                if (response.status === 401) {
                    response.text().then(data => alert(`Unauthorized Access. ${data}`))
                } else {
                    response.json().then(data => this.setState({orgs: data}))
                }
            });
    }

    async deleteOrg(org) {
        if (window.confirm("Are you sure you want to delete this organization ?")) {
            const email = localStorage.getItem('email')
            const params = new URLSearchParams({ email: email });
            const url = `${MSEndpoint}/deleteOrg?${params}`;
              
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(org),
            }).then(() => {
                let updatedOrgs = [...this.state.orgs].filter(o => o.id !== org.id);
                this.setState(oldState => ({ ...oldState, orgs: updatedOrgs }));
            });
        }
    }

    async retryDelayedExecutions(org) {
        if (window.confirm("Are you sure you want to retry delayed executions for this organization ?")) {
            const email = localStorage.getItem('email')
            const url = `${MSEndpoint}/retryDelayedExecutions`;
              
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ orgName: org.name , email: email }),
            })
        }
    }
    
    render() {
        const {orgs: orgs, isLoading} = this.state;
        const {logout} = this.props.auth0

        if (isLoading) {
            return <p>Loading...</p>;
        }
    
        const orgList = orgs.map(org => {
            return <tr key={org.id}>
                <td style={{whiteSpace: 'nowrap'}}>{org.name}</td>
                <td>{org.status}</td>
                <td>{org.dbVersion}</td>
                <td style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {org.featureConfig}
                </td>
                <td>{org.defaultLanguage}</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" tag={Link} to={"/orgs/" + org.name}>Edit</Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button size="sm" color="primary" onClick={() => this.deleteOrg(org)}>Delete</Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button size="sm" color="primary" onClick={() => this.retryDelayedExecutions(org)}>Retry Delayed Actions</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });

        const handleLogout = () => {
            localStorage.removeItem('token')
            localStorage.removeItem('orgname')
            localStorage.removeItem('email')
            console.log("in management console logout")
            logout()
        }
    
        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/users/new">Add User</Button>
                        <Button color="danger" onClick={() => handleLogout()}>Logout</Button>
                    </div>
                    <h3>Orgs</h3>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="20%">Name</th>
                            <th width="20%">Status</th>
                            <th width="20%">DB Version</th>
                            <th width="20%">FeatureConfig</th>
                            <th width="20%">Default Language</th>
                            <th width="20%">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orgList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}
export default withAuth0(OrgList);
