import React from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import OrgCache from "./OrgCache";
import UserCache from "./UserCache";
import { ORG_LEVEL_CACHE, USER_LEVEL_CACHE } from "../../CacheManagement";

const CacheDetails = ({
    orgCacheData,
    orgAssociatedUsers,
    onInvalidateOrg,
    onInvalidateUser,
    isLoading,
    activeTab,
    setActiveTab, // Receive setActiveTab from props
}) => {

    return (
        <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={activeTab === ORG_LEVEL_CACHE ? "active" : ""}
                        onClick={() => setActiveTab(ORG_LEVEL_CACHE)}

                    >
                        Org Level Cache
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === USER_LEVEL_CACHE ? "active" : ""}
                        onClick={() => setActiveTab(USER_LEVEL_CACHE)}
                    >
                        User Level Cache
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="mt-3">
                {activeTab === ORG_LEVEL_CACHE ? (
                    <OrgCache
                        orgCacheData={orgCacheData}
                        onInvalidate={onInvalidateOrg}
                        isLoading={isLoading}
                    />
                ) : (
                    <UserCache
                        orgAssociatedUsers={orgAssociatedUsers}
                        onInvalidate={onInvalidateUser}
                        isLoading={isLoading}
                    />
                )}
            </TabContent>
        </div>
    );
};

export default CacheDetails;
