import React, { useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

const Sidebar = ({ items, onSelect, activeItem, searchPlaceholder, isOrg }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredItems = items.filter((item) =>
    isOrg
      ? item.name.toLowerCase().includes(searchTerm.toLowerCase())
      : item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className="sidebar bg-light"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "350px",
        padding: "1rem",
        overflowY: "auto",
        borderRight: "1px solid #ddd",
        zIndex: 1000, // Ensure it stays above other elements
      }}
    >
      <input
        type="text"
        className="form-control mb-3"
        placeholder={searchPlaceholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ListGroup>
        {filteredItems.map((item) => (
          <ListGroupItem
            key={isOrg ? item.name : item.email}
            active={activeItem === item}
            onClick={() => onSelect(item)}
            style={{
              cursor: "pointer",
              padding: "0.75rem 1rem",
              border: "1px solid #ddd",
              marginBottom: "0.5rem",
              borderRadius: "4px",
            }}
          >
            {
            isOrg ? <>
                <strong>Org Name: {item.name}</strong>
                <div>DB version: {item.dbVersion}</div>
                <div>Status: {item.status}</div>
            </> : 
            <><strong>{item.email}</strong></>
            }
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export default Sidebar;
