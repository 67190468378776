import { withAuth0 } from '@auth0/auth0-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Table, Spinner } from 'reactstrap';
import AppNavbar from './AppNavbar';

const MSEndpoint = require("./config").MSEndpoint

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        };
        this.timers = {};
        this.remove = this.remove.bind(this);
        this.config = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }

    }

    componentDidMount() {
        fetch(MSEndpoint + '/user', this.config)
            .then(response => {
                if (response.status === 401) {
                    response.text().then(data => alert(`Unauthorized Access. ${data}`))
                } else {
                    response.json().then(data => {
                        const userData = data.map(user => ({
                            ...user,
                            loading: false,
                            loadingRecreateTime: 0
                        }));
                        this.setState({ users: userData })
                    })
                }
            })
    }


    async remove(item) {
        this.setState(oldState => ({ users: oldState.users.map(u => u.email === item.email ? { ...u, loading: true } : u) }))
        await fetch(MSEndpoint + `/deleteUser`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(item),
        }).then(() => {
            let updatedUsers = [...this.state.users].filter(i => i.email !== item.email);
            this.setState({ users: updatedUsers });
        });
    }

    async recreateOrg(item) {
        this.setState(oldState => ({ users: oldState.users.map(u => u.email === item.email ? { ...u, loading: true } : u) }))
        this.timers[item.email] = setInterval(() => {
            this.setState(oldState => ({ users: oldState.users.map(u => u.email === item.email ? { ...u, loadingRecreateTime: u.loadingRecreateTime + 1 } : u) }))
        }, 1000);


        await new Promise((resolve) => setTimeout(resolve, 2000));

        await fetch(MSEndpoint + `/recreateOrg`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(item),
        }).then(() => {
            let updatedUsers = [...this.state.users].filter(i => i.email !== item.email);
            clearInterval(this.timers[item.email]);
            this.setState(oldState => ({
                users: oldState.users.map(u =>
                    u.email === item.email ? { ...u, loading: false, loadingRecreateTime: 0 } : u
                )
            }));
            this.setState({ users: updatedUsers });
        });
    }

    render() {
        const { users: users, isLoading } = this.state;
        const { logout } = this.props.auth0

        if (isLoading) {
            return <p>Loading...</p>;
        }

        const userList = users.map(user => {
            return <tr key={user.id}>
                <td style={{ whiteSpace: 'nowrap' }}>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.role}</td>
                <td>{user.hasUiLoginAccess ? "true" : "false"}</td>
                <td>{user.orgName}</td>
                <td>{user.email}</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" disabled={user.loading} tag={Link} to={"/users/" + user.email}>Edit</Button>
                        &nbsp;
                        <Button size="sm" color="danger" disabled={user.loading} onClick={() => this.remove(user)}>Delete</Button>
                        &nbsp;
                        <Button size="sm" color="warning" disabled={user.loading} onClick={() => this.recreateOrg(user)}>
                            {user.loading ?
                                <Spinner size="sm" color='light' /> : <>Re-Create Org</>}
                        </Button>
                        &nbsp;
                        {user.loading && (<div>{user.loadingRecreateTime}s</div>)}
                    </ButtonGroup>
                </td>
            </tr>
        });

        const handleLogout = () => {
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('email')
            console.log("in management console logout")
            logout()

        }

        return (
            <div>
                <AppNavbar />
                <Container fluid>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/users/new">Add User</Button>
                        <Button color="danger" onClick={() => handleLogout()}>Logout</Button>
                    </div>
                    <h3>Users</h3>
                    <Table className="mt-4">
                        <thead>
                            <tr>
                                <th width="15%">FirstName</th>
                                <th width="15%">LastName</th>
                                <th width="5%">UserRole</th>
                                <th width="5%">LoginAccess</th>
                                <th width="20%">OrgName</th>
                                <th width="20%">Email</th>
                                <th width="20%">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}
export default withAuth0(UserList);
