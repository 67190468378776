// SqlExecutor.js

import React, { useState, useEffect } from "react";
import { Container, Toast, ToastBody, ToastHeader, Spinner } from "reactstrap";
import { executeSqlQuery, fetchOrgs } from "./dataManager";
import SqlEditor from "./components/sqlExecutor/SqlEditor";
import QueryResult from "./components/sqlExecutor/QueryResult";
import Sidebar from "./components/cacheManagement/Sidebar";

/**
 * Main component for executing SQL queries against selected organizations.
 */
const SqlExecutor = () => {
    const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [sqlQuery, setSqlQuery] = useState("");
    const [queryResult, setQueryResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // State for messages (array to handle multiple messages)
    const [messages, setMessages] = useState([]); // Each message: { id, type, text }

    // Load organizations on component mount
    useEffect(() => {
        const loadOrgs = async () => {
            try {
                const orgData = await fetchOrgs();
                setOrgs(orgData);
            } catch (error) {
                console.error(error.message);
                showMessage(
                    "error",
                    `Error fetching organizations: ${error.message}`
                );
            }
        };
        loadOrgs();
    }, []);

    // Handle organization selection
    const handleOrgSelect = (org) => {
        setSelectedOrg(org);
        setQueryResult(null);
        setError(null);
    };

    const preProcessSqlQuery = (sqlQuery) => {
        // Remove comments
        let processedSqlQuery = sqlQuery
            .replace(/--.*?(\r\n|\r|\n|$)/g, "") // Remove single-line comments
            .replace(/\/\*[\s\S]*?\*\//g, "") // Remove multi-line comments
            .trim(); // Trim leading/trailing whitespace
    
        // Remove empty lines
        processedSqlQuery = processedSqlQuery.replace(/^\s*\n/gm, "");
    
        return processedSqlQuery;
    };    

    // Handle SQL query submission
    const handleSqlSubmit = async () => {
        if (!selectedOrg) {
            showMessage("error", "Please select an organization first.");
            return;
        }

        const processedSqlQuery = preProcessSqlQuery(sqlQuery);

        if (!processedSqlQuery || processedSqlQuery === "") {
            showMessage("error", "Please enter an SQL query.");
            return;
        }
        setIsLoading(true);
        setQueryResult(null);
        setError(null);
        try {
            const result = await executeSqlQuery(
                selectedOrg.userEmail,
                selectedOrg.name,
                processedSqlQuery
            );
            
            if (result.error) {
                console.log(result.error);
                throw new Error(result.error);
            }

            setQueryResult(result);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to display messages
    const showMessage = (type, text) => {
        const id = Date.now();
        setMessages((prevMessages) => [...prevMessages, { id, type, text }]);

        // Automatically remove the message after 2 seconds
        setTimeout(() => {
            setMessages((prevMessages) =>
                prevMessages.filter((msg) => msg.id !== id)
            );
        }, 2000);
    };

    // Function to manually dismiss a message
    const dismissMessage = (id) => {
        setMessages((prevMessages) =>
            prevMessages.filter((msg) => msg.id !== id)
        );
    };

    return (
        <div>
            <Sidebar
                items={orgs}
                onSelect={handleOrgSelect}
                activeItem={selectedOrg}
                searchPlaceholder="Search organizations by name"
                isOrg
            />
            <div style={{ marginLeft: "350px", padding: "1rem" }}>
                <Container fluid className="mt-2">
                    {/* Toast Notifications Container */}
                    <div
                        style={{
                            position: "fixed",
                            top: "20px",
                            right: "20px",
                            zIndex: 9999,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }}
                    >
                        {messages.map((msg) => (
                            <Toast
                                key={msg.id}
                                isOpen={true}
                                toggle={() => dismissMessage(msg.id)}
                                style={{
                                    minWidth: "250px",
                                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                }}
                            >
                                <ToastHeader
                                    icon={
                                        msg.type === "success"
                                            ? "success"
                                            : "danger"
                                    }
                                    toggle={() => dismissMessage(msg.id)}
                                    className={
                                        msg.type === "success"
                                            ? "bg-success text-white"
                                            : "bg-danger text-white"
                                    }
                                >
                                    {msg.type === "success"
                                        ? "Success"
                                        : "Error"}
                                </ToastHeader>
                                <ToastBody>{msg.text}</ToastBody>
                            </Toast>
                        ))}
                    </div>

                    {selectedOrg ? (
                        <div>
                            <SqlEditor
                                sqlQuery={sqlQuery}
                                onChange={(value) => setSqlQuery(value)}
                                onSubmit={handleSqlSubmit}
                            />
                            {isLoading ? (
                                <div className="text-center mt-3">
                                    <Spinner color="primary" />
                                </div>
                            ) : (
                                <QueryResult
                                    queryResult={queryResult}
                                    error={error}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="text-center mt-5">
                            <h4>
                                Select an organization from the sidebar to
                                execute SQL queries.
                            </h4>
                        </div>
                    )}
                </Container>
            </div>
        </div>
    );
};

export default SqlExecutor;
