import React from "react";
import { Button, Spinner } from "reactstrap";
import ReactJson from "react-json-view";

const OrgCache = ({ orgCacheData, onInvalidate, isLoading }) => (
    <div
        style={{
            padding: "1rem",
            border: "1px solid #ddd",
            borderRadius: "4px",
        }}
    >
        <Button color="danger" onClick={onInvalidate} className="mb-3">
            Invalidate Org Cache And Associated User Caches
        </Button>
        {isLoading ? (
            <Spinner color="primary" />
        ) : orgCacheData ? (
            <ReactJson
                src={orgCacheData}
                theme="google" // Choose a color theme
                collapsed={2}
                displayDataTypes={false} // Hide data types (e.g., string, number)
                enableClipboard={false} // Allow copying JSON to clipboard
                style={{
                    overflowY: "auto",
                    maxHeight: "80vh",
                    fontSize: "13px",
                    whiteSpace: "pre-wrap", // Preserve spacing and allow wrapping
                    wordWrap: "break-word", // Break words that are too long
                }}
            />
        ) : (
            <p>No Org Cache Data Available</p>
        )}
    </div>
);

export default OrgCache;
