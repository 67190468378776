
const domain = getDomainFromUrl(
  getEnvVarWithFallback(
    ['REACT_APP_MS_UI_AUTH0_DOMAIN', 'REACT_APP_UI_AUTH0_DOMAIN'],
    "https://stage-cimba.us.auth0.com"
  )
)

const audience = getEnvVarWithFallback(
  ['REACT_APP_MS_UI_AUTH0_AUDIENCE', 'REACT_APP_UI_AUTH0_AUDIENCE'],
  'https://data-facade-security/'
)

export const clientId = process.env.REACT_APP_CONTROL_PLANE_UI_CLIENT_ID !== undefined ? process.env.REACT_APP_CONTROL_PLANE_UI_CLIENT_ID : "xu2ONosWygSXhPdKea7JiWPlREEyroiA"

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  return {
    domain: domain,
    clientId: clientId,
    ...(audience ? { audience } : null),
  };
}

function getDomainFromUrl(url) {
  const urlObj = new URL(url);
  return urlObj.hostname;
}


/**
 * Get the value of the first available environment variable, or use a default value if none are set.
 *
 * @param vars - An array of environment variable names to check in order.
 * @param defaultValue - The default value to use if none of the environment variables are set.
 * @returns The value of the first found environment variable or the default value.
 */
function getEnvVarWithFallback(vars, defaultValue) {
  for (const variable of vars) {
    const value = process.env[variable];
    if (value !== undefined) {
      return value;
    }
  }
  return defaultValue;
}