import React, { useState, useEffect } from "react";
import { Container, Toast, ToastBody, ToastHeader } from "reactstrap";
import {
  fetchOrgs,
  fetchOrgUsers,
  fetchCacheValue,
  invalidateAllOrgAndAssociatedUserLevelCache,
  invalidateAllUserLevelCache,
} from "./dataManager";
import Sidebar from "./components/cacheManagement/Sidebar";
import CacheDetails from "./components/cacheManagement/CacheDetails";

export const ORG_LEVEL_CACHE = "org_cache";
export const USER_LEVEL_CACHE = "user_cache";

const CacheManagement = () => {
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [orgCacheData, setOrgCacheData] = useState(null);
  const [orgAssociatedUsers, setOrgAssociatedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(ORG_LEVEL_CACHE);

  // State for messages (array to handle multiple messages)
  const [messages, setMessages] = useState([]); // Each message: { id, type, text }

  useEffect(() => {
    const loadOrgs = async () => {
      try {
        const orgData = await fetchOrgs();
        setOrgs(orgData);
      } catch (error) {
        console.error(error.message);
        showMessage("error", `Error fetching organizations: ${error.message}`);
      }
    };
    loadOrgs();
  }, []);

  const handleOrgSelect = (orgName) => {
    setSelectedOrg(orgName);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOrg) {
        setIsLoading(true);
        try {
          if (activeTab === ORG_LEVEL_CACHE) {
            const orgCache = await fetchCacheValue(ORG_LEVEL_CACHE, selectedOrg.name);
            setOrgCacheData(orgCache);
          } else if (activeTab === USER_LEVEL_CACHE) {
            const userData = await fetchOrgUsers(selectedOrg.name);
            console.log(userData);
            setOrgAssociatedUsers(userData);
          }
        } catch (error) {
          console.error(error.message);
          showMessage("error", `Error fetching data: ${error.message}`);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [selectedOrg, activeTab]);

  const handleInvalidateOrgCache = async () => {
    if (!selectedOrg) {
      showMessage("error", "Please select an organization first.");
      return;
    }
    try {
      await invalidateAllOrgAndAssociatedUserLevelCache(selectedOrg.name);
      setOrgCacheData(null);
      showMessage("success", "Org Cache invalidated successfully.");
      setActiveTab(ORG_LEVEL_CACHE); // Switch to org cache tab
    } catch (error) {
      console.error(error.message);
      showMessage("error", `Failed to invalidate org cache: ${error.message}`);
    }
  };

  const handleInvalidateUserCache = async (email) => {
    try {
      await invalidateAllUserLevelCache(email);
      showMessage("success", "User Cache invalidated successfully.");
    } catch (error) {
      console.error(error.message);
      showMessage("error", `Failed to invalidate user cache: ${error.message}`);
    }
  };

  // Function to display messages
  const showMessage = (type, text) => {
    const id = Date.now();
    setMessages((prevMessages) => [...prevMessages, { id, type, text }]);

    // Automatically remove the message after 5 seconds
    setTimeout(() => {
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
    }, 2000);
  };

  // Function to manually dismiss a message
  const dismissMessage = (id) => {
    setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
  };

  return (
    <div>
      <Sidebar
        items={orgs}
        onSelect={handleOrgSelect}
        activeItem={selectedOrg}
        searchPlaceholder="Search organizations by name"
        isOrg
      />
      <div style={{ marginLeft: "350px", padding: "1rem" }}>
        <Container fluid className="mt-2">
          {/* Toast Notifications Container */}
          <div
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {messages.map((msg) => (
              <Toast
                key={msg.id}
                isOpen={true}
                toggle={() => dismissMessage(msg.id)}
                style={{
                  minWidth: "250px",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                }}
              >
                <ToastHeader
                  icon={msg.type === "success" ? "success" : "danger"}
                  toggle={() => dismissMessage(msg.id)}
                  className={
                    msg.type === "success" ? "bg-success text-white" : "bg-danger text-white"
                  }
                >
                  {msg.type === "success" ? "Success" : "Error"}
                </ToastHeader>
                <ToastBody>{msg.text}</ToastBody>
              </Toast>
            ))}
          </div>

          {selectedOrg ? (
            <CacheDetails
              orgCacheData={orgCacheData}
              orgAssociatedUsers={orgAssociatedUsers}
              onInvalidateOrg={handleInvalidateOrgCache}
              onInvalidateUser={handleInvalidateUserCache}
              isLoading={isLoading}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ) : (
            <div className="text-center mt-5">
              <h4>Select an organization from the sidebar to view cache details.</h4>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default CacheManagement;
