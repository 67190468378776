import { useAuth0 } from "@auth0/auth0-react";
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AdminList from "./AdminList.tsx";
import './App.css';
import Home from './Home';
import Login from './Login';
import OrgEdit from './OrgEdit';
import OrgList from './OrgList';
import PromptRulesEdit from './PromptRules/PromptRulesEdit';
import PromptRulesList from './PromptRules/PromptRulesList';
import UserEdit from "./UserEdit";
import UserList from './UserList';
import history from "./utils/history";
import CacheManagement from "./CacheManagement";
import SqlExecutor from "./SqlExecutor";

const App = () => {
  const [token, setToken] = React.useState()
  const { isLoading, isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();

  if (user !== undefined) {
    getAccessTokenSilently().then(receivedToken => {
      localStorage.setItem('token', receivedToken)
      localStorage.setItem('email', user.email)
      localStorage.setItem('username', user.name)
      setToken(receivedToken)
    });
  }


  if (isLoading) {
    return (<div>Loading...</div>)
  }
  else if (user === undefined) {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" component={Login}></Route>
        </Switch>
      </Router>
    )
  }
  else if ((user !== undefined && !(user.email.endsWith("@data-facade.com") || user.email.endsWith("@cimba.ai")))) {
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('email')
    logout()
  }
  else if (token !== undefined) {
    return (
      <Router>
        <Switch>
          <Route path='/' exact={true} component={Home} />
          <Route path='/users' exact={true} component={UserList} />
          <Route path='/orgs' exact={true} component={OrgList} />
          <Route path='/users/:email' component={UserEdit} />
          <Route path='/orgs/:name' exact={true} component={OrgEdit} />
          <Route path='/promptRules' exact={true} component={PromptRulesList} />
          <Route path='/promptRules/:id' exact={true} component={PromptRulesEdit} />
          <Route path='/admins' exact={true} component={AdminList} />
          <Route path='/cacheManagement' exact={true} component={CacheManagement} />
          <Route path="/sqlExecutor" exact={true} component={SqlExecutor} />
        </Switch>
      </Router>
    )
  }
  else {
    return (
      <div>Loading...</div>
    )
  }
}

export default App;