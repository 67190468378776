import React, { useState } from "react";
import { Button, Spinner, Collapse } from "reactstrap";
import ReactJson from "react-json-view";
import { fetchCacheValue } from "../../dataManager";
import { USER_LEVEL_CACHE } from "../../CacheManagement";

const UserCache = ({ orgAssociatedUsers, onInvalidate, isLoading }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [expandedUsers, setExpandedUsers] = useState({});
    const [loadingUsers, setLoadingUsers] = useState([]);

    const filteredUsers = orgAssociatedUsers.filter((user) =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleToggle = async (email) => {
        if (expandedUsers[email]) {
            // Collapse the user cache data
            setExpandedUsers((prev) => {
                const newExpanded = { ...prev };
                delete newExpanded[email];
                return newExpanded;
            });
        } else {
            // Expand and fetch the user cache data
            setLoadingUsers((prev) => [...prev, email]);
            try {
                const cacheData = await fetchCacheValue(
                    USER_LEVEL_CACHE,
                    email
                );
                setExpandedUsers((prev) => ({
                    ...prev,
                    [email]: cacheData,
                }));
            } catch (error) {
                console.error(`Failed to fetch cache for ${email}:`, error);
                alert(`Failed to fetch cache for ${email}: ${error.message}`);
            } finally {
                setLoadingUsers((prev) => prev.filter((e) => e !== email));
            }
        }
    };

    const onClickInvalidateCache = (email) => {
        setExpandedUsers((prev) => {
            const newExpanded = { ...prev };
            delete newExpanded[email];
            return newExpanded;
        });

        onInvalidate(email);
    };

    return (
        <div>
            <input
                type="text"
                placeholder="Search by email"
                className="form-control mb-3"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            {isLoading ? (
                <div className="text-center">
                    <Spinner color="primary" />
                </div>
            ) : filteredUsers && filteredUsers.length ? (
                filteredUsers.map((user) => {
                    const isExpanded = expandedUsers.hasOwnProperty(user.email);
                    const isUserLoading = loadingUsers.includes(user.email);
                    return (
                        <div
                            key={user.email}
                            className="mb-3 border rounded p-2 bg-light"
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <strong>Email: {user.email}</strong>
                                    <div>First Name: {user.firstName}</div>
                                    <div>Last Name: {user.lastName}</div>
                                </div>
                                <div>
                                    <Button
                                        color="primary"
                                        onClick={() => handleToggle(user.email)}
                                        style={{
                                            width: "120px",
                                            marginRight: "10px",
                                        }}
                                    >
                                        {isExpanded ? "Hide Data" : "Show Data"}
                                    </Button>
                                    <Button
                                        color="danger"
                                        onClick={() =>
                                            onClickInvalidateCache(user.email)
                                        }
                                    >
                                        Invalidate Cache
                                    </Button>
                                </div>
                            </div>
                            <Collapse isOpen={isExpanded}>
                                <div
                                    style={{
                                        maxHeight: "70vh",
                                        overflowY: "auto",
                                        marginTop: "1rem",
                                        padding: "0.5rem",
                                        border: "1px solid #ddd",
                                        borderRadius: "4px",
                                        backgroundColor: "#f9f9f9",
                                    }}
                                >
                                    {isUserLoading ? (
                                        <div className="text-center">
                                            <Spinner color="primary" />
                                        </div>
                                    ) : expandedUsers[user.email] ? (
                                        <ReactJson
                                            src={expandedUsers[user.email]}
                                            theme="google"
                                            collapsed={3}
                                            displayDataTypes={false}
                                            enableClipboard={false}
                                            style={{
                                                overflowY: "auto",
                                                maxHeight: "80vh",
                                                fontSize: "13px",
                                                whiteSpace: "pre-wrap", // Preserve spacing and allow wrapping
                                                wordWrap: "break-word", // Break words that are too long
                                            }}
                                        />
                                    ) : (
                                        <p>No Cache Data Available</p>
                                    )}
                                </div>
                            </Collapse>
                        </div>
                    );
                })
            ) : (
                <p>No User Cache Data Available</p>
            )}
        </div>
    );
};

export default UserCache;
