import React from "react";
import { Button } from "reactstrap";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-github";


/**
 * Component for editing SQL queries using AceEditor.
 */
const SqlEditor = ({ sqlQuery, onChange, onSubmit }) => {
    // Set the max height to fit within the screen, with some padding
    const editorMaxHeight = "700px"; // Adjust as needed

    return (
        <div
            style={{
                marginBottom: "1rem",
                border: "1px solid #ddd",
                borderRadius: "4px",
                padding: "1rem",
                maxHeight: editorMaxHeight,
                overflow: "hidden",
            }}
        >
            <AceEditor
                mode="sql"
                name="sql_editor"
                theme="github"
                placeholder={`Example Sql Query: SELECT "column1", "column2" FROM schema_name."table_name"`}
                onChange={onChange}
                value={sqlQuery}
                width="100%"
                height="600px"
                fontSize={13}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                }}
                style={{ marginBottom: "1rem" }}
            />
            <Button color="primary" onClick={onSubmit}>
                Execute Query
            </Button>
        </div>
    );
};

export default SqlEditor;
