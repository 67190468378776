const MSEndpoint = require("./config").MSEndpoint;

export const fetchOrgs = async () => {
    const response = await fetch(`${MSEndpoint}/org`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });

    if (!response.ok) {
        throw new Error(
            `Failed to fetch organizations. Status: ${response.status}`
        );
    }

    const data = await response.json();
    return data;
};

export const fetchOrgUsers = async (orgName) => {
    const response = await fetch(`${MSEndpoint}/orgUsers?orgName=${encodeURIComponent(orgName)}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch users. Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
};

export const fetchCacheValue = async (cacheLevel, identifiersList) => {
    const encodedIdentifiersList = encodeURIComponent(identifiersList);

    const response = await fetch(
        `${MSEndpoint}/getAllCacheValues?cacheLevel=${cacheLevel}&identifiersList=${encodedIdentifiersList}`,
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
    );

    if (!response.ok) {
        throw new Error(
            `Failed to fetch cache values. Status: ${response.status}`
        );
    }

    const data = await response.json();
    return data;
};

export const invalidateAllOrgAndAssociatedUserLevelCache = async (
    orgName = ""
) => {
    // Construct URL with optional orgName parameter
    const url = new URL(
        `${MSEndpoint}/invalidateAllOrgAndAssociatedUserLevelCache`
    );
    if (orgName) {
        url.searchParams.append("orgName", orgName);
    }

    const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });

    if (!response.ok) {
        throw new Error(
            `Failed to invalidate cache. Status: ${response.status}`
        );
    }

    const data = await response.text();
    return data;
};

export const invalidateAllUserLevelCache = async (email = "") => {
    // Construct URL with optional email parameter
    const url = new URL(`${MSEndpoint}/invalidateAllUserLevelCache`);
    if (email) {
        url.searchParams.append("email", email);
    }

    const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });

    if (!response.ok) {
        throw new Error(
            `Failed to invalidate cache. Status: ${response.status}`
        );
    }

    const data = await response.text();
    return data;
};

export const executeSqlQuery = async (orgEmail, orgName, sqlQuery) => {
    // Construct URL with query parameters
    const url = new URL(`${MSEndpoint}/executeSqlQuery`);
    url.searchParams.append('orgEmail', orgEmail);
    url.searchParams.append('orgName', orgName);
    url.searchParams.append('sqlQuery', sqlQuery);

    // Prepare headers
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    // Make the POST request
    const response = await fetch(url.toString(), {
        method: 'POST',
        headers,
    });

    // Handle response
    if (!response.ok) {
        throw new Error(`Failed to execute SQL query. Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
};

