import React, { useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
// Import AG-Grid styles
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

/**
 * Component for displaying query results or error messages.
 */
const QueryResult = ({ queryResult, error }) => {
  // Reference to AG-Grid
  const gridRef = useRef(null);

  // Set the max height and width for the result area
  const resultMaxHeight = '60vh'; // Adjust as needed
  const resultMaxWidth = '100%';

  // Button styling (optional)
  const buttonStyle = {
    padding: '0.5rem 1rem',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  // Handler for CSV export
  const onBtnExport = () => {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv({
        fileName: 'query-results.csv',
        // Additional export options can be added here
      });
    }
  };

  if (error) {
    return (
      <div
        style={{
          border: '1px solid #ddd',
          borderRadius: '4px',
          padding: '1rem',
          color: 'red',
        }}
      >
        <strong>Error:</strong> {error}
      </div>
    );
  }

  if (!queryResult) {
    return null; // No result to show
  }

  if (queryResult.queryType !== 'read') {
    console.log(queryResult);
    return (
      <div
        style={{
          border: '1px solid #ddd',
          borderRadius: '4px',
          padding: '1rem',
        }}
      >
        <strong>Result:</strong>
        <pre>{queryResult.writeQueryResponse}</pre>
      </div>
    );
  }

  // queryType is 'read', render the AG-Grid table
  const { columnNames, data } = queryResult;

  // Define column definitions for AG-Grid
  const columnDefs = columnNames.map((colName) => ({
    headerName: colName,
    field: colName,
    sortable: true,
    filter: true,
    flex: 1, // Flexibly adjust column width
    tooltipField: colName, // Show tooltip on hover
    valueFormatter: ({ value }) => (value === null || value === undefined ? 'null' : value),
    // Custom cellRenderer to display all values as plain text
    cellRenderer: (params) => {
      const value = params.value;
      if (value === null || value === undefined) {
        return 'null';
      }
      return value.toString();
    },
  }));

  // Prepare row data by mapping each row to an object with key-value pairs
  const rowData = data.map((row, rowIndex) => {
    const rowObject = {};
    columnNames.forEach((colName, colIndex) => {
      rowObject[colName] = row[colIndex];
    });
    // AG-Grid requires a unique 'id' for each row
    rowObject.id = rowIndex; // You can use a different unique identifier if available
    return rowObject;
  });

  return (
    <div
      style={{
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '1rem',
        maxHeight: resultMaxHeight,
        maxWidth: resultMaxWidth,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {/* Download CSV Button */}
      <div>
        <button onClick={onBtnExport} style={buttonStyle}>
          Download CSV
        </button>
      </div>

      {/* AG-Grid Table */}
      <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{
            resizable: true, // Allows resizing columns
            minWidth: 100, // Minimum column width
            flex: 1, // Distribute remaining space
            editable: true, // Enable cell editing by default
            tooltipComponent: 'agTooltipComponent', // Use default tooltip
          }}
          pagination={true} // Enable pagination
          paginationPageSize={10} // Number of rows per page
          domLayout="autoHeight" // Adjust grid height based on content
        />
      </div>
    </div>
  );
};

export default QueryResult;
